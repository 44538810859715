import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { OptimizedTextField } from "../../../../Parts/General/OptimizedTextField";
import { iInformationForChabber, FormStates } from "./EditPostingTypes";

interface IProps {
  formState: FormStates;
  informationForChabbers: iInformationForChabber;
  errors: { [key: string]: { message: string; isErr: boolean } };

  changeValues: (value: any, name: string) => void;
  clientValidation: (name: string, value: any) => void;
}
interface IState {}

// https://trello.com/c/67AM8MtE/273-update-to-the-job-description-feature
const MAX_CHAR_LIMIT_FOR_DESCRIPTION = 2400;

export class InformationForChabber extends Component<IProps, IState> {
  shouldUpdateWasTrue: boolean = false;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h2 className="brand">
          {getLanguage(408, "Information for the chabber")}
        </h2>
        <Grid container spacing={4}>
          <Grid item lg={12}>
            <OptimizedTextField
              type="text"
              label={getLanguage(409, "Chabbers needs to bring")}
              margin="dense"
              placeholder={getLanguage(
                410,
                "E.g. black pants and a white shirt"
              )}
              value={this.props.informationForChabbers.chabberMustBring || ""}
              onChange={(e) =>
                this.props.changeValues(e.target.value, "chabberMustBring")
              }
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "loadForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <OptimizedTextField
              type="text"
              label={`${getLanguage(278, "Job description")} - ${
                this.props.informationForChabbers.jobDescription?.length || 0
              } / ${MAX_CHAR_LIMIT_FOR_DESCRIPTION}`}
              multiline
              required={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              rows="4"
              margin="dense"
              placeholder={getLanguage(
                411,
                "Please describe the job and it's tasks..."
              )}
              value={this.props.informationForChabbers.jobDescription || ""}
              onChange={(e) =>
                this.props.changeValues(e.target.value, "jobDescription")
              }
              error={
                (this.props.informationForChabbers.jobDescription?.length ?? 0) >
                  MAX_CHAR_LIMIT_FOR_DESCRIPTION ||
                (this.props.errors["jobDescription"] &&
                  this.props.errors["jobDescription"].isErr)
              }
              helperText={
                this.props.errors["jobDescription"] &&
                this.props.errors["jobDescription"].message
              }
              onFocus={(e) =>
                this.props.clientValidation("jobDescription", "_")
              } //Clears the error
              onBlur={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
                  ? (e) =>
                      this.props.clientValidation(
                        "jobDescription",
                        e.target.value
                      )
                  : undefined
              }
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "loadForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
            />
          </Grid>
          <Grid item lg={12}>
            <OptimizedTextField
              type="text"
              label={getLanguage(
                955,
                "Contact information on person in charge of job"
              )}
              margin="dense"
              placeholder={getLanguage(
                955,
                "Contact information on person in charge of job"
              )}
              value={this.props.informationForChabbers.contact || ""}
              onChange={(e) =>
                this.props.changeValues(e.target.value, "contact")
              }
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "loadForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <OptimizedTextField
              type="text"
              label={getLanguage(77, "Address")}
              margin="dense"
              value={this.props.informationForChabbers.address || ""}
              onChange={(e) =>
                this.props.changeValues(e.target.value, "address")
              }
              onBlur={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
                  ? (e) =>
                      this.props.clientValidation("address", e.target.value)
                  : undefined
              }
              error={
                this.props.errors["address"] &&
                this.props.errors["address"].isErr
              }
              helperText={
                this.props.errors["address"] &&
                this.props.errors["address"].message
              }
              required={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
            />
          </Grid>
          <Grid item lg={3}>
            <OptimizedTextField
              type="text"
              label={getLanguage(78, "Zip code")}
              margin="dense"
              required={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              value={this.props.informationForChabbers.zip || ""}
              onChange={(e) => this.props.changeValues(e.target.value, "zip")}
              error={this.props.errors["zip"] && this.props.errors["zip"].isErr}
              helperText={
                this.props.errors["zip"] && this.props.errors["zip"].message
              }
              onBlur={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
                  ? (e) => this.props.clientValidation("zip", e.target.value)
                  : undefined
              }
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
            />
          </Grid>
          <Grid item lg={3}>
            <OptimizedTextField
              type="text"
              label={getLanguage(79, "City")}
              margin="dense"
              value={this.props.informationForChabbers.city || ""}
              onChange={(e) => this.props.changeValues(e.target.value, "city")}
              error={
                this.props.errors["city"] && this.props.errors["city"].isErr
              }
              helperText={
                this.props.errors["city"] && this.props.errors["city"].message
              }
              onBlur={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
                  ? (e) => this.props.clientValidation("city", e.target.value)
                  : undefined
              }
              required={
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
