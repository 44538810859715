// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Grid,
  Button,
  Dialog,
  Chip,
  Zoom,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import quarantine from '../../../../../assets/img/illustrations/quarantine.svg';
import noJobs from '../../../../../assets/img/illustrations/noJobs.svg';
import noFilters from '../../../../../assets/img/illustrations/noFilters.svg';
import { PostingCard } from '../PostingCard';
import { PostingHelper } from '../../../../../Helpers/PostingHelper';
import { PostingFull } from '../PostingFull';
import { PostingModel } from '../../../../../Models/PostingModel';
import { PageLoader } from '../../../../Parts/General/PageLoader';
import { getCurrentUser } from '../../../../../Helpers/SessionHelper';
import { LoginModel } from '../../../../../Models/LoginModel';
import { UserHelper } from '../../../../../Helpers/UserHelper';
import { CompanyHelper } from '../../../../../Helpers/CompanyHelper';
import { RequestHelper } from '../../../../../Helpers/RequestHelper';
import { RequestModel } from '../../../../../Models/RequestModel';
import { FavoriteProfileModel } from '../../../../../Models/FavoriteProfileModel';
import { SearchPostingsFilterDrawer } from './SearchPostingsFilterDrawer';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';
import { JobType } from './SearchpostTypes';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';
import { IsProfileIncomplete } from '../IsProfileIncomplete/IsProfileIncomplete';
import { SnackbarManager } from '../../../../../Helpers/SnackbarManager/SnackbarManager';
import { getQuery } from '../../../../../Helpers/queryHelper';


interface IProps extends RouteComponentProps { }
interface IState {
  pageState: 'loading' | 'ready' | 'visitor' | '';
  filterDrawerOpen: boolean;
  postings: PostingModel[];
  filteredPostings: PostingModel[];
  selectedPosting: PostingModel | null;
  currentUser: LoginModel | null;
  isQuarantined: boolean;
  workingDays: moment.Moment[];
  requests: RequestModel[];
  favouriteCompanies: FavoriteProfileModel[];
  filterObject: {
    date: { isActive: boolean, value: Date | undefined },
    distance: { isActive: boolean, value: number },
    zip: { isActive: boolean, value: string },
  };
  jobTypes: JobType[];
  existingRequests: any;
  existingPostings: any;
  cancelledJobs: PostingModel[];
  companyDeclinedJobs: PostingModel[];
  postingsIndexesAppliedFor: number[];
}

export class SearchPostings extends Component<IProps, IState> {
  postingHelper = new PostingHelper();

  userHelper = new UserHelper();

  companyHelper = new CompanyHelper();

  requestHelper = new RequestHelper();

  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    const skills = this.storageHelper.getSkillsFromLocal();

    const user = getCurrentUser();
    const jobTypes: JobType[] = [];


    if (user && user.Freelancer && user.Freelancer.ProfileSkills) {
      if (skills && skills.length !== 0) {
        for (const i of skills) {
          if (i.Activated !== false) {
            jobTypes.push({
              title: getLanguage(i.getLanguageCode(1, false)),
              state: (user!.Freelancer!.ProfileSkills.findIndex((y) => y.SkillId === i.Id) !== -1) ? 'selected' : 'unavailable',
              id: i.Id,
            });
          }
        }
      }
    }

    this.state = {
      pageState: '',
      filterDrawerOpen: false,
      postings: [],
      filteredPostings: [],
      selectedPosting: null,
      currentUser: user,
      isQuarantined: false,
      workingDays: [],
      requests: [],
      favouriteCompanies: [],
      filterObject: {
        date: { isActive: false, value: undefined },
        distance: { isActive: false, value: 50 },
        zip: { isActive: false, value: '' },
      },
      jobTypes,
      existingRequests: null,
      existingPostings: null,
      cancelledJobs: [],
      companyDeclinedJobs: [],
      postingsIndexesAppliedFor: []
    };

    this.filterChange = this.filterChange.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.changedJobtype = this.changedJobtype.bind(this);
    this.applyJobtypeFiltering = this.applyJobtypeFiltering.bind(this);
    this.closePreview = this.closePreview.bind(this);
  }

  componentDidMount() {
    const currentUser = getCurrentUser();
    const inFreelancer = this.props.location.pathname.toLowerCase().includes('freelancer');

    if (!inFreelancer && currentUser != null && currentUser.Freelancer != null) {
      this.props.history.push({ pathname: '/freelancer/searchpostings', search: this.props.history.location.search });
      return;
    }

    this.fetchData();
  }

  async fetchData() {
    this.setState({ pageState: 'loading' });
    const currentUser = getCurrentUser();

    let isQuarantined = false;

    // Get quarentine information
    if (currentUser != null) {
      const quarentine = await this.userHelper.getQuarentine();
      isQuarantined = quarentine !== null && typeof (quarentine) === 'object';

      if (isQuarantined) {
        this.setState({ isQuarantined, pageState: 'ready' });
        return;
      }
    }
    let favouriteCompanies: FavoriteProfileModel[] = [];
    if (currentUser) {
      favouriteCompanies = await this.userHelper.getFavorites();
    }

    const timeNow = new Date().toString();

    // Logans comment: What is this supposed to do? FIXME: check this out with Steffen
    const { existingRequests } = this.state; // FIXME: probably doesn't work

    let allRequests: RequestModel[] = [];


    if (existingRequests && currentUser) {
      if (existingRequests.timeCreated && existingRequests.timeCreated > timeNow) {
        allRequests = existingRequests.requests;
      } else {
        allRequests = await this.requestHelper.getAllRequestsForFreelancer();
        if (allRequests) {
          this.cacheRequests(allRequests);
        }
      }
    } else if (currentUser) {
      allRequests = await this.requestHelper.getAllRequestsForFreelancer();
      if (allRequests) {
        this.cacheRequests(allRequests);
      }
    }

    // If chabber cancels a job they were confirmed for, they can NOT search any jobs that day.
    // If chabber cancels a job before they are confirmed, they CAN search jobs that day.
    // If chabber gets declined by company they can NOT search the job again UNLESS they were declined automatically (the job gets filled)
    // If chabber is confirmed for job they can NOT see other jobs on that day.


    // ==Useful variables== //
    // DeclinedByCompany
    // ChabberCancelledAfterHired


    const futureRequests = allRequests.filter((x) => x.WorkDateTime != null && moment(x.WorkDateTime).isSameOrAfter(moment(), 'date'));

    const workingDays = futureRequests.filter((x) => x.RequestStatus === 3).map((x) => moment(x.WorkDateTime || undefined));


    // RequestStatus: 0 (Open)
    // RequestStatus: 1 (Rejected (either by chabber or company))
    // RequestStatus: 3 (Confirmed)

    const cancelledJobs = futureRequests.filter((x) => x.RequestStatus === 1
      && x.ChabberCancelledAfterHired === true
      && x.DeclinedByCompany === false).map((x) => x.Posting) as PostingModel[];


    const companyDeclinedJobs = futureRequests.filter((x) => x.DeclinedByCompany === true
      && x.RequestStatus === 1
      && (x.Posting != null && x.Posting.ReopenedDate != null && x.Created != null
        && x.Posting.ReopenedDate < x.Created)).map((x) => x.Posting) as PostingModel[];


    let pageState: 'ready' | 'visitor' = 'ready';
    if (currentUser == null) {
      pageState = 'visitor';
    }

    const { existingPostings } = this.state;

    let allPostings: boolean | PostingModel[] = [];


    if (existingPostings) {
      if (existingPostings.timeCreated && existingPostings.timeCreated > timeNow) {
        allPostings = existingPostings.postings;
      } else {
        allPostings = await this.postingHelper.getAllPostings();
        if (allPostings) {
          this.cachePostings(allPostings);
        }
      }
    } else {
      allPostings = await this.postingHelper.getAllPostings();
      if (allPostings) { this.cachePostings(allPostings); }
    }


    if (allPostings !== false) {

      let filteredPostings = allPostings !== true ? allPostings : [];
      let qId = getQuery('id', this.props.history.location.search);
      let selectedPosting = null;
      if (qId) {
        let qIndex = filteredPostings.findIndex(x => x.Id === qId);
        if (qIndex !== -1) selectedPosting = filteredPostings[qIndex];
        else SnackbarManager.Instance.addError(getLanguage(836, 'posting not found'))
      }
      await this.setState({
        requests: allRequests,
        pageState,
        postings: allPostings !== true ? allPostings : [],
        filteredPostings,
        currentUser,
        isQuarantined,
        workingDays,
        cancelledJobs,
        companyDeclinedJobs,
        favouriteCompanies,
        selectedPosting,
      });
    } else {
      this.setState({ pageState: 'ready', currentUser });
    }

    this.preliminaryFiltering();
  }


  async cachePostings(postings: PostingModel[]) {
    let timeNow = new Date();
    timeNow = new Date(timeNow.getTime() + 5 * 60000);

    let newExistingPostings: { postings: PostingModel[], timeCreated: string } | null = null;
    if (postings) {
      newExistingPostings = { postings, timeCreated: timeNow.toString() };
      this.setState({ existingPostings: newExistingPostings });
    }
  }

  async cacheRequests(requests: RequestModel[]) {
    let timeNow = new Date();
    timeNow = new Date(timeNow.getTime() + 5 * 60000);

    let newExistingRequests: { requests: RequestModel[], timeCreated: string } | null = null;
    if (requests) {
      newExistingRequests = { requests, timeCreated: timeNow.toString() };
      this.setState({ existingRequests: newExistingRequests });
    }
  }


  async preliminaryFiltering() {
    let { postings } = this.state;
    const currentUser = getCurrentUser();
    if (currentUser == null) return;

    // Remove all jobs on the same day as jobs being worked
    postings = postings.filter((x) => this.state.workingDays.find((y) => y.isSame(moment(x.StartAtUTC), 'date')) == null);
    // Remove all jobs that have been requested already
    postings = postings.filter((x) => this.state.requests.find((y) => (y.PostingId === x.Id && y.RequestStatus === 3) || (y.PostingId === x.Id && y.RequestStatus === 0)) == null);
    // Remove all cancelled jobs, and jobs on the same day as cancelled jobs
    postings = postings.filter((x) => this.state.cancelledJobs.find((y) => moment(x.StartAtUTC).isSame(moment(y.StartAtUTC), 'date')) == null);
    // Remove all where skill type is not in freelancer's selected skills
    postings = postings.filter((x) => currentUser.Freelancer?.ProfileSkills.find((y) => y.SkillId === x.SkillId))
    // Remove all posts that the company has removed
    const newPostings = [];
    for (const post of postings) {
      if (this.state.companyDeclinedJobs.findIndex((x) => x.Id === post.Id) === -1) {
        newPostings.push(post);
      }
    }
    postings = newPostings;

    await this.setState({ postings, filteredPostings: postings });
    this.applyJobtypeFiltering();
  }

  async changedJobtype(checked: boolean, title: string) {
    const { jobTypes } = this.state;
    const selectedIndex = jobTypes.findIndex((x) => x.title === title);
    if (selectedIndex !== -1) {
      jobTypes[selectedIndex].state = checked === true ? 'selected' : 'unselected';
    }

    await this.setState({ jobTypes });
    if (checked === true) {
      this.filterChange('jobtype', null);
    } else {
      this.applyJobtypeFiltering();
    }
  }

  applyJobtypeFiltering() {
    const postings = this.state.filteredPostings;
    const jobtypes = this.state.jobTypes.filter((x) => x.state === 'selected');

    const filteredPostings = postings.filter((x) => jobtypes.find((y) => x.Skill != null && y.id === x.Skill.Id) != null);
    this.setState({ filteredPostings });
  }

  viewPosting(selectedPosting: PostingModel) {
    this.props.history.push({ search: 'id=' + selectedPosting.Id });
    this.setState({ selectedPosting });
  }

  render() {
    const postingDialogue = this.renderPostingDialogue();

    let pageloader;
    if (this.state.pageState === 'loading') {
      if (!this.state.currentUser) {
        pageloader = <PageLoader />;
      } else {
        pageloader = <PageLoader releaseNavbar />;
      }
    }

    const chips = this.renderChips();

    return (
      <div className="searchPostings">
        {pageloader}

        {/* <ComponentLoader isLoading={this.state.pageState === 'loading'}> */}
        <div className="margin-top-24">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1 className="inline-block">{getLanguage(59, 'Find shifts')}</h1>
            </Grid>
            <Grid item hidden={this.state.currentUser == null}>
              <div className="inputGroup">
                <Button
                  className="white inputGroup_item"
                  variant="contained"
                  onClick={() => this.setState({ filterDrawerOpen: true })}
                >
                  <span className={chips.length > 0 ? 'active btn-icon' : 'btn-icon'}>
                    <i className="fas fa-sliders-h" />
                  </span>
                  {getLanguage(210, 'Filter')}
                </Button>
                {/* <Button
                  className="white inputGroup_item"
                  variant="contained"
                >
                  <span className="btn-icon">
                    <i className="fas fa-map" />
                  </span>
                {getLanguage(330,"Map")}
                </Button>  */}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="margin-bottom-24">
          {chips}
        </div>
        <div className="postingListWrapper margin-bottom-32">
          <Grid container spacing={4}>
            {this.state.filteredPostings.map((posting, index) => (
              <Zoom key={index} in={this.state.postingsIndexesAppliedFor.find(t => t === index) == null} unmountOnExit timeout={400}>
                <Grid key={posting.Id} item lg={4} md={6} sm={12} xs={12}>
                  <div onClick={() => this.viewPosting(posting)}>
                    <PostingCard posting={posting} favourites={this.state.favouriteCompanies} />
                  </div>
                </Grid>
              </Zoom>
            ))}
          </Grid>
        </div>
        {/* </ComponentLoader> */}


        {/* No jobs atm  */}
        <div className={(this.state.pageState === 'ready' && this.state.postings.length === 0 && this.state.isQuarantined === false) ? 'emptyState_wrapper' : 'emptyState_wrapper hidden'}>
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={noJobs}
              alt="No jobs available"
            />
            <h1 className="margin-bottom-0">{getLanguage(282, 'No available jobs')}</h1>
            <p className="infoParagraph">{getLanguage(283, "There are no jobs that match your profile at the moment. We'll notify you when new jobs are posted.")}</p>
          </div>
        </div>

        {/* Filters cause a loss of jobs */}
        <div
          className={
            this.state.postings.length > 0
              && this.state.filteredPostings.length === 0
              ? 'emptyState_wrapper'
              : 'emptyState_wrapper hidden'
          }
        >
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={noFilters}
              alt="Co-operate with collegues"
            />
            <h1 className="margin-bottom-0">{getLanguage(284, 'No results')}</h1>
            <p className="infoParagraph">{getLanguage(285, 'You have filtered out all available jobs. Try to reset or adjust your filtering.')}</p>
          </div>
        </div>

        {/* Is quarentined */}
        <div
          className={
            this.state.isQuarantined === true
              ? 'emptyState_wrapper'
              : 'emptyState_wrapper hidden'
          }
        >
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={quarantine}
              alt="Co-operate with collegues"
            />
            <h1 className="margin-bottom-0">{getLanguage(286, 'Your profile has been quarantined')}</h1>
            <p className="infoParagraph">{getLanguage(287, 'You have been registered as not showing up for a shift and therefore you cannot apply for other jobs. Please contact customer support, if you have any questions.')}</p>
          </div>
        </div>

        {postingDialogue}
        {this.renderFilterDrawer()}
        <IsProfileIncomplete history={this.props.history} match={this.props.match} location={this.props.location} checkForExpiration={null} />
      </div>
    );
  }

  renderChips() {
    const chips: JSX.Element[] = [];

    for (const [key, value] of Object.entries(this.state.filterObject)) {
      if (value.isActive) {
        let label: string | JSX.Element = '';
        switch (key) {
          case 'date':
            label = moment(value.value).format('ll');
            break;
          case 'distance':
            label = `Radius: ${value.value} km`;
            break;
          case 'zip':
            label = 'address';
            break;
        }


        chips.push(
          <Chip
            key={key}
            classes={{ root: 'chip', deleteIcon: 'deleteIcon', outlined: 'chipOutlined' }}
            label={label}
            // variant="outlined"
            clickable={false}
            onDelete={() => {
              this.removeChip(key, value);
            }}
            deleteIcon={<i className="fas fa-times" />}
          />,
        );
      }
    }
    return chips;
  }

  async removeChip(key: string, value: { isActive: boolean, value: any }) {
    switch (key) {
      case 'distance':
        value.isActive = false;
        value.value = 50;
        break;
    }
    this.filterChange(key, value.value);
  }

  clearAllFilters() {
    const { jobTypes } = this.state;
    for (const jobType of jobTypes) {
      jobType.state = jobType.state === 'unselected' ? 'selected' : jobType.state;
    }

    this.setState({
      filterObject: {
        date: { isActive: false, value: undefined },
        distance: { isActive: false, value: 50 },
        zip: { isActive: false, value: '' },
      },
      filteredPostings: this.state.postings,
      jobTypes,
    });

    this.preliminaryFiltering();
  }


  renderPostingDialogue() {
    let postingFull = <div />;
    // Is the user favourited by the company?
    let isFavourite = false;
    if (this.state.selectedPosting != null && this.state.selectedPosting.AutoHireFavourites === true) {
      const companyId = this.state.selectedPosting.CompanyId;
      // FIXME: We shouldn't use CompanyProfileId
      if (this.state.favouriteCompanies) {
        isFavourite = this.state.favouriteCompanies.findIndex((x) => x.Company != null && x.ApprovalStatus === 1 && (x.CompanyId === companyId || x.CompanyProfileId === companyId)) !== -1;
      }
    }

    if (this.state.selectedPosting != null) {
      postingFull = (
        <PostingFull
          preview
          visiting={this.state.pageState === 'visitor'}
          posting={this.state.selectedPosting}
          viewState="freelancer-searching"
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          isChabberFavourite={isFavourite}
          closePostingPreview={this.closePreview}
          favourites={this.state.favouriteCompanies}
          request={this.state.requests.sort((a, b) => new Date(b.Created) - new Date(a.Created)).find(x => x.PostingId === this.state.selectedPosting?.Id)}
        />
      );
    }

    return (
      <>
        {/* DESKTOP DIALOG DRAWER */}
        {/* <Hidden only="xs"> */}
        <Dialog
          open={this.state.selectedPosting != null}
          onClose={() => {
            this.props.history.push({ search: '' });
            this.setState({ selectedPosting: null });
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          scroll="body"
          classes={{ paperFullWidth: 'fullWidthDialog searchPostingDialog' }}
        >
          {postingFull}
        </Dialog>
        {/* </Hidden> */}

        {/* MOBILE SWIPABLE DRAWER */}
        {/* <Hidden smUp>
          <SwipeableDrawer
            anchor="bottom"
            open={this.state.selectedPosting != null}
            onClose={() => this.setState({ selectedPosting: null })}
            onOpen={() => { }}
            aria-labelledby="form-dialog-title"
            classes={{ paperAnchorBottom: 'swipeableDrawer_searchPostings' }}
            disableDiscovery={true}
          >
            {postingFull}
          </SwipeableDrawer>
        </Hidden> */}
      </>
    );
  }

  async closePreview() {
    const { filteredPostings } = this.state;
    if (this.state.selectedPosting) {
      for (let i = 0; i < filteredPostings.length; i++) {
        if (filteredPostings[i].Id === this.state.selectedPosting.Id) {
          let postingsIndexesAppliedFor = this.state.postingsIndexesAppliedFor
          postingsIndexesAppliedFor.push(i)
          await this.setState({ postingsIndexesAppliedFor });
        }
      }
    }
    SnackbarManager.Instance.addSuccess(getLanguage(672, 'Application sent'));
    await this.setState({
      selectedPosting: null,
    });
  }

  renderFilterDrawer() {
    if (this.state.filterDrawerOpen) {
      return (
        <SearchPostingsFilterDrawer
          filterDrawerOpen={this.state.filterDrawerOpen}
          closeDrawer={() => this.setState({ filterDrawerOpen: false })}
          filterObj={this.state.filterObject}
          filterChange={this.filterChange}
          allJobs={this.state.filteredPostings}
          allJobsNonFiltered={this.state.postings}
          clearAllFilters={this.clearAllFilters}
          jobTypes={this.state.jobTypes}
          changedJobtype={this.changedJobtype}
        />
      );
    }
    return '';
  }

  async filterChange(name: string, val: any) {
    switch (name) {
      case 'date':
        const oldDate = moment(this.state.filterObject.date.value);
        let date: { isActive: boolean, value: Date | undefined };
        if (oldDate && oldDate.isSame(val, 'date') && this.state.filterObject.date.isActive) {
          date = { value: undefined, isActive: false };
        } else {
          date = { value: val, isActive: true };
        }

        await this.setState((prev) => ({
          filterObject: {
            ...prev.filterObject,
            date,
          },
        }));
        break;

      case 'distance':
        const isActive = val !== 50;
        this.setState((prev) => ({ filterObject: { ...prev.filterObject, distance: { isActive, value: val } } }));
        break;
      case 'zip':
        this.setState((prev) => ({ filterObject: { ...prev.filterObject, zip: val } }));
        break;
    }

    let { postings } = this.state;

    for (const [key, value] of Object.entries(this.state.filterObject)) {
      if (value.isActive === true || key === 'distance') {
        switch (key) {
          case 'date':

            const date = value.value as Moment | undefined;
            if (date !== undefined) {
              postings = postings.filter((x) => moment(x.StartAtLocal).isSame(date, 'date'));
            }
            break;
          case 'distance':
            postings = postings.filter((x) => x.DistanceFromUserInKm != null && x.DistanceFromUserInKm <= this.state.filterObject.distance.value);
            break;
          case 'zip':
            break;
        }
      }
    }

    await this.setState({ filteredPostings: postings });
    this.applyJobtypeFiltering();
  }
}
