import { includes, isNil } from "lodash";
import { VerificationDocumentsStatus } from "../../../../../Models/VerificationDocumentStatus";
import moment, { Moment } from "moment";

export const VerificationDocumentKeys = [
  "NationalID",
  "PictureId",
  "WorkPermit",
  "WorkPermitBack",
] as const;

export enum DocumentVerificationStatus {
  "NotRequired" = 0,
  "Required" = 1,
  "Uploaded" = 2,
  "Approved" = 3,
  "Rejected" = 4,
}

export type DocumentsVerificationStatus =
  | "verification-pending"
  | "action-required"
  | "approved";

export const resolveDocumentsVerificationStatus = (
  userVerificationData: VerificationDocumentsStatus,
  workPermitHasToBeValidAt?: Moment
) => {
  let documentsVerificationStatus: DocumentsVerificationStatus | undefined;
  for (const verificationDocumentKey of VerificationDocumentKeys) {
    const documentVerificationStatus = userVerificationData[
      verificationDocumentKey
    ] as DocumentVerificationStatus | null | undefined;
    if (isNil(documentVerificationStatus)) {
      documentsVerificationStatus = "action-required";
      break;
    }

    //check if any verification is pending
    if (
      includes(
        [DocumentVerificationStatus.Uploaded],
        documentVerificationStatus
      )
    ) {
      documentsVerificationStatus = "verification-pending";
      break;
    }

    //check if any document is rejected or awaiting upload
    if (
      includes(
        [
          DocumentVerificationStatus.Rejected,
          DocumentVerificationStatus.Required,
        ],
        documentVerificationStatus
      )
    ) {
      documentsVerificationStatus = "action-required";
      break;
    }

    //check if next document is approved or not required
    if (
      includes(
        [
          DocumentVerificationStatus.NotRequired,
          DocumentVerificationStatus.Approved,
        ],
        documentVerificationStatus
      )
    ) {
      //check if work permit is valid at shift start date, not null WorkPermitExpirationUTC means that document was required
      if (
        !!workPermitHasToBeValidAt &&
        !!userVerificationData.WorkPermitExpirationUTC
      ) {
        if (
          moment(userVerificationData.WorkPermitExpirationUTC) <
          moment(workPermitHasToBeValidAt)
        ) {
          documentsVerificationStatus = "action-required";
          break;
        }
      }
      documentsVerificationStatus = "approved";
    }
  }

  return documentsVerificationStatus;
};
